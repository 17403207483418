<template>
  <div>
    <b-modal
      ref="add-venue"
      centered
      hide-footer
      hide-header
      class="position-relative"
      size="lg"
      @hide="hideModal"
    >
      <div
        class="m-2"
      >
        <validation-observer ref="addVenue">
          <b-form-row>
            <!-- add venue heading -->
            <b-col
              lg="12"
              xl="12"
              md="12"
              sm="12"
              class="mb-2"
            >
              <div
                class="d-inline-block d-flex justify-content-center align-content-center align-items-center"
              >
                <span class="font-weight-bolder heading1">
                  Add Venue
                </span>
              </div>
              <div
                class="closediv"
              >
                <b-button
                  variant="transparent"
                  class="closebtn"
                  @click="hideModal()"
                >
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    size="16"
                  />
                </b-button>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="headingVenue">
                Basic Information
              </div>
            </b-col>
            <!-- Venue Name input -->
            <b-col
              lg="6"
              xl="6"
              md="6"
              sm="12"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="venue-name"
                name="Venue Name"
                rules="required"
              >
                <b-form-group
                  label="Venue Name"
                  class="label"
                >
                  <b-form-input
                    id="venue-name"
                    v-model="venue.venue_name"
                    placeholder="Enter Venue Name"
                    trim
                    class="input1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Venue Type input -->
            <b-col
              lg="6"
              xl="6"
              md="6"
              sm="12"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Venue Type"
                vid="venue-type"
                rules="required"
              >
                <b-form-group
                  text-bolder
                  label="Select Venue Type"
                  class="label"
                >
                  <v-select
                    v-model="venue.venue_type_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="venueList"
                    :state="errors.length > 0 ? false:null"
                    input-id="venue-type"
                    class="search"
                    label="venue_type_name"
                    :reduce="selectVenueType => selectVenueType.id"
                    placeholder="Select Venue Type"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <div class="headingVenue">
                Primary Contact
              </div>
            </b-col>
            <!-- Venue Primary Contact - Email -->
            <b-col
              lg="6"
              xl="6"
              md="6"
              sm="12"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="Venue_primary_contact_email"
                name="Venue Primary Contact - Email"
                rules="required|email"
              >
                <b-form-group
                  label="Venue Primary Contact - Email"
                  class="label"
                >
                  <b-form-input
                    id="Venue_primary_contact_email"
                    v-model="venue.contact_email"
                    placeholder="Enter Venue Primary Contact - Email"
                    trim
                    class="input1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Venue Type input -->
            <b-col
              lg="6"
              xl="6"
              md="6"
              sm="12"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="Venue Primary Contact - Phone Number"
                vid="Venue_primary_contact_phone"
                rules="required"
              >
                <b-form-group
                  label="Venue Primary Contact - Phone Number"
                  class="label"
                >
                  <VuePhoneNumberInput
                    v-model="venue.number"
                    default-country-code="AU"
                    valid-color="#005A9C"
                    placeholder="Secondary"
                    @update="isValid"
                  />
                  <small
                    class="text-danger"
                  >{{ errors.length > 0 ? errors[0] : errorMessage }}</small>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col cols="7">
              <div class="headingVenue">
                Venue Address
              </div>
            </b-col>
            <b-col
              cols="5"
              class="d-flex justify-content-end font-weight-bold text-dark"
            >
              <div
                class="d-flex justify-content-around w-55 enter-manually"
              >
                Enter Manually
                <div>
                  <b-form-checkbox
                    v-model="checked"
                    name="check-button"
                    switch
                  />
                </div>
              </div>
            </b-col>
            <b-col
              v-show="!checked"
              lg="12"
              xl="12"
              md="12"
              sm="12"
            >
              <GooglePlacesApi
                ref="googlePlaces"
                @address-selected="getAddressData"
              />
            </b-col>
            <!-- Address input -->
            <b-col
              lg="6"
              xl="6"
              md="6"
              sm="12"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="address"
                name="Address"
                rules="required"
              >
                <b-form-group
                  text-bolder
                  label="Address"
                  class="label"
                >
                  <b-form-input
                    id="venue-name"
                    v-model="venue.venue_address.address"
                    placeholder="Enter Address"
                    trim
                    class="input"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Suburb input -->
            <b-col
              lg="6"
              xl="6"
              md="6"
              sm="12"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="suburb"
                name="Suburb"
                rules="required"
              >
                <b-form-group
                  text-bolder
                  label="Suburb"
                  class="label"
                >
                  <b-form-input
                    id="suburb"
                    v-model="venue.venue_address.suburb"
                    placeholder="Enter Suburb"
                    trim
                    class="input"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Postal Code input -->
            <b-col
              lg="6"
              xl="6"
              md="6"
              sm="12"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="postal-code"
                name="Postal Code"
                rules="required"
              >
                <b-form-group
                  text-bolder
                  label="Postal Code"
                  class="label"
                >
                  <b-form-input
                    id="postal-code"
                    v-model="venue.venue_address.postal_code"
                    type="number"
                    placeholder="Enter Postal Code"
                    trim
                    class="input1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Country Name input -->
            <b-col
              lg="6"
              xl="6"
              md="6"
              sm="12"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Country Name"
                vid="country-name"
                rules="required"
              >
                <b-form-group
                  text-bolder
                  label="Select Country"
                  class="label"
                >
                  <v-select
                    v-model="venue.venue_address.country"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="$store.getters['devices/getCountries']"
                    :state="errors.length > 0 ? false:null"
                    input-id="country-name"
                    class="search"
                    label="name"
                    :reduce="allCountries => allCountries.name"
                    placeholder="Select Country"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Screen Latitude input -->
            <b-col
              lg="6"
              xl="6"
              md="6"
              sm="12"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="Screen Latitude"
                vid="screen-lat"
                rules="required|lat"
              >
                <b-form-group
                  text-bolder
                  label="Venue Latitude"
                  class="label"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="screen-lat"
                    v-model="venue.point.lat"
                    type="number"
                    placeholder="Enter Venue Latitude"
                    variant="primary"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <!-- Screen Longitude input -->
            <b-col
              lg="6"
              xl="6"
              md="6"
              sm="12"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="Screen Longitude"
                vid="screen-lng"
                rules="required|lng"
              >
                <b-form-group
                  text-bolder
                  label="Venue Longitude"
                  class="label"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="screen-lng"
                    v-model="venue.point.lng"
                    type="number"
                    placeholder="Please Venue Longitude"
                    variant="primary"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
              class="bg-white mt-2"
            >
              <CardQuillEditor
                name="Venue Audience Profile"
                @audienceProfile="audienceProfile"
              />
            </b-col>
            <b-col cols="12">
              <div>
                Find your coordinates at <a
                  target="_blank"
                  class="text-primary"
                  href="https://www.latlong.net/"
                > www.latlong.net </a>
              </div>
            </b-col>
            <!-- Venue Operating Hours -->
            <b-col cols="12">
              <div class="headingVenue">
                Venue Operating Hours
              </div>
            </b-col>
            <!-- Start Time selector-->
            <b-col
              lg="6"
              md="6"
              xl="6"
              sm="12"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="Start Time"
                vid="screen-start-time"
                rules="required"
              >
                <b-form-group
                  label="Please Add Your Start Time"
                  class="label"
                >
                  <b-form-timepicker
                    id="screen-start-time"
                    v-model="venue.start_time"
                    placeholder="00:00"
                    variant="primary"
                    locale="en"
                    :show-seconds="false"
                    :state="startTimer"
                    :hour12="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <!-- End Time selector-->
            <b-col
              lg="6"
              md="6"
              xl="6"
              sm="12"
              class="mb-1"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="End Time"
                vid="screen-end-time"
                rules="required"
              >
                <b-form-group
                  label="Please Add Your End Time"
                  class="label"
                >
                  <b-form-timepicker
                    id="screen-end-time"
                    v-model="venue.end_time"
                    placeholder="00:00"
                    variant="primary"
                    locale="en"
                    :show-seconds="false"
                    :state="endTimer"
                    :hour12="false"
                    :disabled="!endTimeField"
                    @input="compareTimes"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <!-- submit buttons -->
            <b-col
              lg="12"
              xl="12"
              md="12"
              sm="12"
              class="d-flex justify-content-center align-items-center"
            >
              <div
                class="form-group w-50"
              >
                <b-button
                  block
                  variant="primary"
                  :disabled="spinner"
                  type="submit"
                  @click="addVenue($event)"
                >
                  <div
                    v-if="spinner"
                    class="spinner"
                  >
                    <b-spinner
                      small
                    />
                    <span class="sr-only">Loading...</span>
                  </div>
                  Add
                </b-button>
              </div>
            </b-col>
          </b-form-row>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton,
  BModal,
  BFormInput,
  BFormGroup,
  VBModal,
  BCol,
  BSpinner,
  BFormRow,
  BFormTimepicker,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { showToast } from '@/common/global/functions'
import AccountTypes from '@/common/enums/accountTypeEnum'
import GooglePlacesApi from '@/components/common/GooglePlacesApi.vue'
import CardQuillEditor from '../common/CardQuillEditor.vue'

export default {
  components: {
    vSelect,
    BFormRow,
    BButton,
    BFormInput,
    BFormGroup,
    BModal,
    BCol,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput,
    BFormTimepicker,
    BFormCheckbox,
    GooglePlacesApi,
    CardQuillEditor,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      spinner: false,
      required,
      AccountTypes,
      venue: {
        venue_name: '',
        point: {
          lng: '',
          lat: '',
        },
        venue_address: {
          address: '',
          suburb: '',
          postal_code: '',
          country: '',
        },
        venue_type_id: [],
        number: '',
        contact_email: '',
        start_time: null,
        end_time: null,
        audience_profile: null,
      },
      errorMessage: '',
      isValidNumber: true,
      validNumber: '',
      endTimer: null,
      startTimer: null,
      checked: false,
    }
  },
  computed: {
    venueList() {
      return this.$store.getters['venues/getVenueTypeList']
    },
    userRole() {
      return this.$store.getters['user/getUserRole']
    },
    countries() {
      return this.$store.getters['devices/getCountries']
    },
    endTimeField() {
      if (!this.venue.start_time) {
        return false
      }
      return true
    },
  },
  created() {
    this.onLoad()
    this.getCountriesList()
  },
  methods: {
    audienceProfile(data) {
      this.venue.audience_profile = data
    },
    getAddressData(addressData) {
      const place = addressData
      this.venue.point.lat = ''
      this.venue.point.lng = ''
      this.venue.venue_address.address = ''
      this.venue.venue_address.suburb = ''
      this.venue.venue_address.postal_code = ''
      this.venue.venue_address.country = ''
      place.address_components.forEach(component => {
        const componentType = component.types[0]

        switch (componentType) {
          case 'street_number': {
            this.venue.venue_address.address = `${component.long_name} ${this.venue.venue_address.address}`
            break
          }
          case 'route': {
            this.venue.venue_address.address += component.short_name
            break
          }
          case 'sublocality_level_1': {
            this.venue.venue_address.address = `${this.venue.venue_address.address} ${component.long_name}`
            break
          }
          case 'sublocality_level_2': {
            this.venue.venue_address.address = `${this.venue.venue_address.address} ${component.long_name}`
            break
          }
          case 'postal_code': {
            this.venue.venue_address.postal_code = `${component.long_name}`
            break
          }
          case 'locality': {
            this.venue.venue_address.suburb = component?.long_name
            break
          }
          case 'country': {
            this.venue.venue_address.country = component.long_name
            break
          }
          default: {
            break
          }
        }
      })
      if (place?.geometry?.location) {
        this.venue.point.lng = place?.geometry?.location.lng()
        this.venue.point.lat = place?.geometry?.location.lat()
      }
    },
    resetDataWhenModalClosed() {
      this.selectVenueType = []
      this.venue.venue_name = ''
      this.venue.point.lat = ''
      this.venue.point.lng = ''
      this.venue.venue_address.address = ''
      this.venue.venue_address.suburb = ''
      this.venue.venue_address.postal_code = ''
      this.venue.venue_address.country = ''
      this.venue.venue_type_id = ''
      this.venue.number = ''
      this.venue.audience_profile = null
      this.venue.contact_email = ''
      this.venue.start_time = null
      this.venue.end_time = null
      this.errorMessage = ''
      this.endTimer = null
      this.startTimer = null
      this.checked = false
    },
    async onLoad() {
      this.spinner = true
      const success = await this.$store.dispatch('venues/getVenueTypeList')
      if (success) {
        this.spinner = false
      } else {
        showToast('Error', 'Error fetching venue type lists', 'danger')
        this.spinner = false
        this.hideModal()
      }
    },
    isValid(data) {
      this.isValidNumber = data.isValid
      if (this.isValidNumber === false) {
        this.errorMessage = 'Please enter a Valid Venue Primary Contact - Phone Number'
      } else {
        this.errorMessage = ''
        this.validNumber = data
      }
    },
    show() {
      this.$refs['add-venue'].show()
    },
    hideModal() {
      this.resetDataWhenModalClosed()
      this.$refs.googlePlaces.removeScrollListener()
      this.$refs['add-venue'].hide()
    },
    async getCountriesList() {
      await this.$store.dispatch('devices/getAllCountries')
    },
    compareTimes() {
      const [h1, m1, s1] = this.venue.start_time.split(':').map(Number)
      const [h2, m2, s2] = this.venue.end_time.split(':').map(Number)
      const date1 = new Date()
      date1.setHours(h1, m1, s1, 0)

      const date2 = new Date()
      date2.setHours(h2, m2, s2, 0)

      if (date1.getTime() > date2.getTime()) {
        this.endTimer = false
        this.startTimer = true
      } else if (date1.getTime() < date2.getTime()) {
        this.endTimer = true
        this.startTimer = true
      } else {
        this.result = 'Time 1 is equal to Time 2'
        this.endTimer = true
        this.startTimer = true
      }
    },
    splitTime() {
      const [hours, minutes] = this.venue.start_time.split(':')
      this.venue.start_time = `${hours}:${minutes}`
      const [hours1, minutes1] = this.venue.end_time.split(':')
      this.venue.end_time = `${hours1}:${minutes1}`
    },
    async addVenue() {
      try {
        const success = await this.$refs.addVenue.validate()
        if (success && this.isValidNumber) {
          this.spinner = true
          this.venue.number = this.validNumber.formattedNumber
          this.splitTime()
          const payload = { ...this.venue }
          const userHasGroupId = this.$route.params.userId

          try {
            const response = await this.$store.dispatch('venues/createVenue', { payload, userHasGroupId })
            if (response) {
              showToast('Create Venue', 'Venue created successfully', 'success')
              this.spinner = false
              this.hideModal()
              this.$emit('reloadVenues')
            }
          } catch (error) {
            this.spinner = false
            if (error.response && error.response.data) {
              const { message } = error.response.data
              if (typeof message === 'string') {
                this.$swal(message)
              } else {
                this.$swal(message[0].toString())
              }
            } else {
              console.log(error)
              this.$swal.fire({
                text: 'An unexpected error occurred',
                icon: 'error',
              })
            }
            this.$emit('reloadVenues')
            this.hideModal()
          }
        } else {
          this.$swal('Enter Valid data')
          this.spinner = false
        }
      } catch (e) {
        console.log(e)
        this.$swal('Enter Valid data')
        this.spinner = false
      }
    },
  },
}
</script>
<style src="vue-it-bigger/dist/vue-it-bigger.min.css"></style>
<style lang="scss">
.vs__dropdown-menu{
  max-height: 250px !important;
}
.form{
  padding-inline: 10px;
}
.add{
  position: relative;
}
.upload{
  position: absolute;
  bottom: 20px;
  right: 54px;
}
.head{
  font-size: 14px;
  line-height: 24px;
  color: #1f58b5;
  font-weight: 600;
  font-family: "Montserrat";
}
.spinner{
  text-align: center;
  z-index: 1070;
}
.compaignlabel{
  font-size: 10px;
  line-height: 18px;
  color: #323232;
  font-weight: 600;
  font-family: "Montserrat";
}
.headingVenue{
  font-size: 20px;
  color: black;
  font-weight: bolder;
}

.pac-container {
  z-index: 1041;
}
@media (max-width: 990px) {
.enter-manually{
  width: 100% !important;
  padding-top: 5px;
}
}

@media (max-width: 550px) {
.enter-manually{
  font-size: 12px;
}
.headingVenue{
  font-size: 18px;
}
.modal{
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
}

</style>
