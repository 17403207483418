<template>
  <div>
    <div class="d-flex justify-content-end mt-0" />
    <b-card
      no-body
    >
      <div
        class="m-2"
      >
        <b-row class="d-flex flex-start">
          <span
            class="pl-2"
          >
            <h4 class="text-primary">All Screens</h4>
          </span>
        </b-row>
      </div>
      <b-skeleton-table
        v-if="spinner"
        :rows="paginationObject.limit"
        :columns="5"
        :table-props="{ bordered: true, striped: true, tableClass:'skeleton-loading-table' }"
      />
      <b-table
        v-else
        id="allGroups-table"
        responsive
        outlined
        hover
        selectable
        :per-page="paginationObject.limit"
        :select-mode="selectMode"
        :items="allScreensList.results"
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
        class="position-relative"
        :sort-by.sync="sortBy"
        @row-selected="onRowSelected"
      >
        <template #cell(ID)="data">
          <span
            class="d-block text-nowrap text-truncate"
            style="max-width: 150px;"
          >
            {{ data.item.id }}
          </span>
        </template>
        <template #cell(Name)="data">
          <span
            class="d-block text-nowrap text-truncate"
            style="max-width: 150px;"
          >
            {{ data.item.name }}
          </span>
        </template>
        <template
          #cell(CPM)="data"
        >
          <span
            class="text-nowrap d-inline-block text-truncate"
            style="max-width: 150px;"
          >
            $ {{ Math.round(data.item.cpm) }}
          </span>
        </template>
        <template
          #cell(Device_Address)="data"
        >
          <span
            v-b-tooltip.hover
            class="text-nowrap d-inline-block text-truncate"
            style="max-width: 200px;"
            :title="data.item.device_address ? data.item.device_address : '--'"
          >
            {{ data.item.device_address || '--' }}
          </span>
        </template>
        <template
          #cell(Impressions)="data"
        >
          <span>
            <span>
              {{ Math.round(data.item.impressions) || '--' }}
            </span>
          </span>
        </template>
      </b-table>
    </b-card>
    <CustomPagination
      v-if="allScreensList.results"
      :total="paginationObject.total"
      :per-page="paginationObject.limit"
      :page="paginationObject.offset"
      @perPageChanged="perPageChanged"
      @pageChanged="pageChanged"
    />
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BTable,
  VBToggle,
  BSkeletonTable,
  VBTooltipPlugin,
} from 'bootstrap-vue'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import CustomPagination from '@/components/common/CustomPagination.vue'
import AccountTypes from '@/common/enums/accountTypeEnum'
import { showToast } from '@/common/global/functions'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)
export default {
  components: {
    CustomPagination,
    BCard,
    BRow,
    BTable,
    BSkeletonTable,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltipPlugin,
  },
  props: {
    venueId: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      allScreensList: {},
      AccountTypes,
      active: false,
      sortBy: 'STATUS',
      searchQuery: '',
      spinner: false,
      allScheduleSlots: [],
      deleteUserId: null,
      paginationObject: {
        offset: 1,
        limit: 5,
        total: 0,
      },
      tableColumns: [
        { key: 'ID' },
        { key: 'Name', label: 'Name' },
        { key: 'CPM', label: 'CPM' },
        { key: 'Device_Address', label: 'Screen Address' },
        { key: 'Impressions', label: 'Impressions' },
      ],
      selectMode: 'single',
    }
  },
  computed: {
    isUserAdmin() {
      return this.$store.getters['user/getUserRole'] === AccountTypes.ADMIN
    },
    isComingFromDashboardVenueDetails() {
      return this.$route.meta.breadcrumb[0].to === '/dashboard/venue-details'
    },
    isVenueId() {
      if (this.venueId) {
        return this.venueId
      }
      if (this.$route.params.venueId) {
        return this.$route.params.venueId
      }
      return this.$route.params.id
    },
  },
  watch: {
    venueId: {
      handler() {
        this.getAllScreenList(this.paginationObject)
      },
    },
  },
  async mounted() {
    await this.getAllScreenList(this.paginationObject)
  },
  methods: {
    async getAllScreenList({ offset, limit }) {
      let response
      try {
        this.spinner = true
        if (this.isUserAdmin) {
          response = await this.$axios.get(`admin/pmp-ad/devices-by-venue?venue_id=${this.isVenueId}&offset=${offset - 1}&limit=${limit}&search_query=`, {
            headers: {
              userHasGroupId: this.$route.params.userId,
            },
          })
        } else {
          response = await this.$axios.get(`/pmp-ads/devices-by-venue?venue_id=${this.isVenueId}&offset=${offset - 1}&limit=${limit}&search_query=`)
        }
        this.allScreensList = response.data.data
        this.paginationObject.total = response.data.data.total
      } catch (error) {
        console.error('error', error.message)
        showToast('All Venues List', error.response.data.message ? error.response.data.message : error.message, 'danger', 4000)
      } finally {
        this.spinner = false
      }
    },
    perPageChanged(limit) {
      this.paginationObject.offset = 1
      this.paginationObject.limit = limit
      this.getPmpAdsSchedulerAnalytics(this.paginationObject)
    },
    pageChanged(offset) {
      this.paginationObject.offset = offset
      this.getPmpAdsSchedulerAnalytics(this.paginationObject)
    },
    setPaginationToDefault() {
      this.paginationObject = {
        offset: 1,
        limit: 5,
        total: 0,
      }
    },
    onRowSelected(item) {
      if (this.$route.name === 'dashboard') {
        this.$router.push({
          name: 'dashboard-single-device-user',
          params: {
            id: item[0].id.toString(),
          },
        })
      } else if (this.$route.name === 'simi-network-single-venue-user') {
        this.$router.push({
          name: 'simi-network-single-device-details',
          params: {
            venueId: this.$route.params.venueId,
            deviceId: item[0].id.toString(),
          },
        })
      } else if (this.$route.name === 'user-single-venue-details') {
        this.$router.push({
          name: 'user-single-venue-screen-details',
          params: {
            userId: this.$route.params.userId,
            venueId: this.$route.params.venueId,
            deviceId: item[0].id.toString(),
            deviceName: item[0].name.toString(),
          },
        })
      } else if (this.$route.name === 'user') {
        this.$router.push({
          name: 'user-single-screen-details',
          params: {
            userId: this.$route.params.userId,
            deviceId: item[0].id.toString(),
            deviceName: item[0].name.toString(),
          },
        })
      } else if (this.$route.name === 'admin-user-venue-details') {
        this.$router.push({
          name: 'user-venue-single-screen-details',
          params: {
            userId: this.$route.params.userId,
            venueId: this.$route.params.venueId,
            deviceId: item[0].id.toString(),
            deviceName: item[0].name.toString(),
          },
        })
      } else {
        this.$router.push({
          name: 'dashboard-venue-device-details',
          params: {
            venueId: this.$route.params.venueId,
            deviceId: item[0].id.toString(),
            deviceName: item[0].name.toString(),
          },
        })
      }
    },
  },
}
</script>

        <style lang="scss" scoped>
        .per-page-selector {
          width: 90px;
        }

        .invoice-filter-select {
          min-width: 190px;

          ::v-deep .vs__selected-options {
            flex-wrap: nowrap;
          }

          ::v-deep .vs__selected {
            width: 100px;
          }
        }
        </style>

        <style lang="scss">
        @import "@core/scss/vue/libs/vue-select.scss";
        .circular_image {
          border-radius: 40px;
          background-color: #eeedfd;
        }
        </style>
