<template>
  <b-card class="cardTabs">
    <b-tabs
      justified
      fill
    >
      <b-tab
        title="Venue Details"
        active
      >
        <div class="d-flex flex-column h-100 heightClass justify-content-around">
          <h3 class="text-primary zoom-hover cursor-pointer">
            {{ statsData ? statsData.venueDetails.venue_name : "--" }}
          </h3>
          <div
            class="d-flex justify-content-between"
          >
            <span
              class="text-nowrap"
            >
              <strong>
                Venue Address:
              </strong>
            </span>
            <span
              v-b-tooltip.hover
              class="text-truncate text-wrap"
              style="max-width: 200px;"
              :title="statsData ? statsData.venueDetails.address : '--'"
            >
              {{ statsData ? statsData.venueDetails.address : '--' }}
            </span>
          </div>
          <div
            class="d-flex justify-content-between"
          >
            <span>
              <strong>
                Revenue Percentage:
              </strong>
            </span>
            <span>
              {{ statsData ? Math.round(statsData.venueDetails.revenue_percentage) : 0 }} %
            </span>
          </div>
          <div
            class="d-flex justify-content-between"
          >
            <span>
              <strong>
                Average Cpm:
              </strong>
            </span>
            <span>
              $ {{ statsData ? (statsData.venueDetails.averageCpm).toFixed(2) : 0 }}
            </span>
          </div>
          <div
            class="d-flex justify-content-between"
          >
            <span>
              <strong>
                Impressions:
              </strong>
            </span>
            <span>
              {{ statsData ? statsData.venueDetails.totalImpressionOfVenue : 0 }}
            </span>
          </div>
          <div
            class="d-flex justify-content-between"
          >
            <span>
              <strong>
                Venue Type:
              </strong>
            </span>
            <span>
              {{ statsData ? statsData.venueDetails.type.venue_type_name : '--' }}
            </span>
          </div>
          <div
            v-if="!shouldShowEmail"
            class="d-flex justify-content-between"
          >
            <span>
              <strong>
                Contact No:
              </strong>
            </span>
            <span>
              {{ statsData ? statsData.venueDetails.number : '--' }}
            </span>
          </div>
          <div
            v-if="!shouldShowEmail"
            class="d-flex justify-content-between"
          >
            <span>
              <strong>
                Email:
              </strong>
            </span>
            <span>
              {{ statsData ? statsData.venueDetails.contact_email : '--' }}
            </span>
          </div>
        </div>
      </b-tab>
      <b-tab
        title="Screen Profile"
        class="heightClass"
      >
        <CardCollapseScreenProfile
          :screens="statsData ? statsData.screenData : null"
        />
      </b-tab>
      <b-tab
        title="Audience Profile"
      >
        <div
          v-if="shouldEditAudienceProfileShow"
          class="d-flex justify-content-end mr-1 mb-50"
        >
          <b-button
            size="sm"
            variant="primary"
            @click="openEditAudienceProfileModal(statsData.audienceProfile)"
          >
            <feather-icon
              icon="EditIcon"
            />
          </b-button>
        </div>
        <div
          class="tab-content-wrapper heightClass"
        >
          <div
            class="textClass1"
            v-html="statsData ? statsData.audienceProfile : 'Nothing to Show'"
          />
        </div>
      </b-tab>
      <b-tab
        title="Rate Card"
        class="rateCard textClass1"
      >
        <b-card
          v-for="(item, index) in statsData.screenData"
          :key="index"
          class="cardScreen "
        >
          <div
            class="d-flex justify-content-start"
          >
            <span
              class="font-weight-bolder font-medium-2 text-primary"
            >
              {{ item.name }}
            </span>
          </div>
          <div
            class="d-flex justify-content-around mt-1"
          >
            <span
              class="font-weight-bolder font-small-5"
            >
              Impressions
            </span>
            <span>
              {{ item.impressions || 0 }}
            </span>
          </div>
          <div
            class="d-flex justify-content-around mt-1"
          >
            <span
              class="font-weight-bolder font-small-5"
            >
              CPM
            </span>
            <span>
              $ {{ item.cpm || 0 }}
            </span>
          </div>
        </b-card>
        <span v-if="statsData.screenData.length === 0">
          No Screens Available
        </span>
      </b-tab>
    </b-tabs>
    <CardEditAudienceProfile
      ref="edit-device-audience-profile"
      :type="!isScreenSelected ? 'Venue' : 'Device'"
      :venue-id="statsData ? statsData.venueDetails ? statsData.venueDetails.id : null : null"
      :device-id="statsData ? statsData.screenData.length > 0 ? statsData.screenData[0].id : null : null"
      :data="statsData.audienceProfile"
      @refresh-data="refreshData"
    />
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BTab,
  BTabs,
  VBTooltipPlugin,
} from 'bootstrap-vue'
import CardCollapseScreenProfile from './CardCollapseScreenProfile.vue'
import CardEditAudienceProfile from './CardEditAudienceProfile.vue'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    CardCollapseScreenProfile,
    BButton,
    CardEditAudienceProfile,
  },
  directives: {
    'b-tooltip': VBTooltipPlugin,
  },
  props: {
    statsData: {
      type: Object,
      default: () => null,
    },
    isScreenSelected: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      editAudienceProfileName: false,
    }
  },
  computed: {
    shouldShowEmail() {
      return this.$route.name === 'simi-network-single-venue-user'
    },
    isAgency() {
      return this.$store.getters['user/getSelectedGroupMemberPermissions'].includes(MemberPermissions.AGENCY)
    },
    shouldEditAudienceProfileShow() {
      if (!this.isAgency) {
        return this.$route.name === 'user' || this.$route.name === 'dashboard' || this.$route.name === 'devices' || this.$route.name === 'admin-user-venue-details'
      }
      return false
    },
  },
  methods: {
    editAudienceProfile() {
      this.editAudienceProfileName = !this.editAudienceProfileName
    },
    openEditAudienceProfileModal(data) {
      this.$refs['edit-device-audience-profile'].showCpmModal(data)
    },
    refreshData() {
      window.location.reload()
    },
  },

}
</script>

<style lang="scss" scoped>
.heightClass{
    min-height: 260px !important;
    max-height: 260px !important;
}
.tab-content-wrapper {
  padding: 1.5rem;
  border-radius: 0 0 6px 6px;
  background: #f8f8f8;
  min-height: 150px !important;
}

.textClass1::-webkit-scrollbar {
  width: 6px;
}
.textClass1::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 50px;
}

.textClass1::-webkit-scrollbar-thumb {
  background-color: #4c92fc;
  border-radius: 50px;
}
.textClass1{

}
.textClass {
  max-height: 230px !important; /* Set max height for scrolling */
  min-width: 400px;
  overflow-y: auto !important;
  display: block; /* Ensure it respects height constraints */
}
.rateCard{
  max-height: 230px !important; /* Set max height for scrolling */
  min-width: 400px;
  padding: 15px;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.cardTabs{
  min-height: 380px !important;
}
.card{
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}

/* Add styles for Quill-generated content */
.quill-content em { font-style: italic; }
.quill-content u { text-decoration: underline; }
.quill-content s { text-decoration: line-through; }

/* Ensure <p> tags have proper spacing */
.quill-content p { margin-bottom: 1rem; }

</style>
