import { render, staticRenderFns } from "./VenueDashboard.vue?vue&type=template&id=9509250e&scoped=true&"
import script from "./VenueDashboard.vue?vue&type=script&lang=js&"
export * from "./VenueDashboard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9509250e",
  null
  
)

export default component.exports