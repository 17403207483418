<template>
  <b-card
    :class="cardVenue ? 'cardCarouselVenue' : 'cardCarousel'"
  > <!-- Add h-100 class to fill parent height -->
    <b-carousel
      v-if="images.length"
      id="carousel-1"
      :interval="2000"
      :controls="cardVenue ? false : true"
      :indicators="cardVenue ? false : true"
      class="d-flex align-items-center"
      style="background-color: #F8F8F9; height: 100%;"
    >
      <b-carousel-slide
        v-for="(image, index) in images"
        :key="index"
        style="height: 100%;"
      >
        <template #img>
          <div class="h-100 d-flex align-items-center"> <!-- Full height container -->
            <b-img
              :src="image"
              alt="image"
              :class="cardVenue ? 'carousel-image1 w-100':'carousel-image w-100'"
            />
          </div>
        </template>
      </b-carousel-slide>
    </b-carousel>
    <div
      v-else
      :class="cardVenue ? 'carousel-image1' : 'cardCarousel d-flex flex-column align-items-center justify-content-center h-100 text-muted'"
    >
      <b-icon
        icon="image"
        variant="secondary"
        font-scale="3"
        class="mb-3"
      />
      <b-card-text class="h4 text-secondary">
        No Images Available
      </b-card-text>
      <b-card-text>
        {{ cardVenue ? '':'Add images to see them here' }}
      </b-card-text>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BCarousel, BCarouselSlide, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BCarousel,
    BCarouselSlide,
    BImg,
    BCard,
    BCardText,
  },
  props: {
    images: {
      type: Array,
      required: true,
      default: () => [],
    },
    cardVenue: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style scoped>
.b-card {
    height: 100%;
  }

  /* Carousel image styling */
  .carousel-image {
    height:335px;
    object-fit: cover !important;
    width: 100%;
  }

  .carousel-image1 {
    height:130px;
    object-fit: cover !important;
    width: 100%;
  }

  /* Carousel inner container adjustment */
  .carousel-inner {
    height: 100%;
  }
  .cardCarousel{
    min-height: 340px !important;
  }
  .cardCarouselVenue{
    min-height: 100px !important;

  }
  .cardCarouselVenue .card-body{
    padding: .75rem;
  }
  .card{
    margin-bottom: 0rem !important;
  }
  .card{
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
  }
</style>
