<template>
  <div>
    <b-row
      v-if="noVenueAvailable"
    >
      <b-col class="d-flex justify-content-center">
        <span>
          Please create venue to see data on Dashboard
        </span>
      </b-col>
    </b-row>
    <div
      v-else
    >
      <b-row
        v-if="isUserAdmin && dataForVenueOrScreen && !listOfVenueLoader"
      >
        <b-col class="d-flex justify-content-start text-primary pb-2 font-medium-3">
          <b-badge
            variant="primary"
            class="badge-glow"
          >
            <span>
              {{ dataForVenueOrScreen.venueDetails.venue_name }}
            </span>
          </b-badge>
        </b-col>
      </b-row>
      <b-row
        class="mb-1"
      >
        <b-col
          v-if="shouldShowDropDown"
          lg="4"
          md="4"
        >
          <b-skeleton
            v-if="listOfVenueLoader"
          />
          <v-select
            v-else
            v-model="currentState"
            class="mr-1 w-m-20"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="venue_name"
            :clearable="false"
            :searchable="false"
            :options="currentStateOptions"
          />
        </b-col>
        <b-col
          lg="8"
          md="8"
        >
          <b-skeleton
            v-if="listOfVenueLoader"
          />
          <span
            v-else
          >
            <b-tabs
              pills
              nav-class="nav-pill-primary d-flex align-items-center"
              style="margin-bottom: 0px;"
            >
              <b-tab
                title="All"
                active
                @click="getDataforVenue(dataForVenueOrScreen ? dataForVenueOrScreen.venueDetails.id : null)"
              />
              <b-tab
                v-for="(tab, index) in dataForVenueOrScreen ? dataForVenueOrScreen.screenList : []"
                :key="index"
                :title="tab.deviceName"
                @click="getDataforScreen(tab)"
              />
            </b-tabs>
          </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          lg="6"
          md="6"
        >
          <b-skeleton-img
            v-if="getVenueDataLoader"
            no-aspect
            height="300px"
          />
          <CarouselForImages
            v-else
            style=""
            :images="dataForVenueOrScreen ? dataForVenueOrScreen.images : []"
          />
        </b-col>
        <b-col
          lg="6"
          md="6"
        >
          <b-skeleton-img
            v-if="getVenueDataLoader"
            no-aspect
            height="300px"
          />
          <CardEarningReports
            v-else
            :stats-data="dataForVenueOrScreen ? dataForVenueOrScreen.earningReports : null"
            :date-sequence="dateSequence"
            :earning-sequence="earningSequence"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          lg="6"
          md="6"
        >
          <b-skeleton-img
            v-if="getVenueDataLoader"
            no-aspect
            height="300px"
          />
          <CardTabsDashboard
            v-else
            :stats-data="dataForVenueOrScreen"
            :is-screen-selected="isScreenSelected"
          />
        </b-col>
        <b-col
          lg="6"
          md="6"
        >
          <b-skeleton-img
            v-if="getVenueDataLoader"
            no-aspect
            height="300px"
          />
          <ExternalAdsTracker
            v-else
            :stats-data="dataForVenueOrScreen ? dataForVenueOrScreen.campaignTracker : null"
          />
        </b-col>
      </b-row>
      <b-row v-if="!singleVenueFromVenueAndScreen">
        <b-col
          lg="12"
          md="12"
        >
          <showDeviceListTable
            v-if="dataForVenueOrScreen"
            :venue-id="currentState.id"
          />
        </b-col>
      </b-row>
    </div>
    <CardSelectGroupModal
      ref="external-admin-select-group"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {
  BRow, BCol,
  BSkeletonImg,
  BSkeleton,
  BTabs,
  BTab,
  BBadge,
} from 'bootstrap-vue'
import { showToast } from '@/common/global/functions'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'
import CarouselForImages from '@/components/common/CarouselForImages.vue'
import CardEarningReports from '@/components/common/CardEarningReports.vue'
import ExternalAdsTracker from '@/components/account-setting/ExternalAdsTracker.vue'
import CardTabsDashboard from '@/components/common/CardTabsDashboard.vue'
import showDeviceListTable from '@/components/simiNetwork/showDeviceListTable.vue'
import AccountTypes from '@/common/enums/accountTypeEnum'
import CardSelectGroupModal from '@/components/common/CardSelectGroupModal.vue'

export default {
  components: {
    CarouselForImages,
    CardEarningReports,
    CardTabsDashboard,
    showDeviceListTable,
    ExternalAdsTracker,
    vSelect,
    BRow,
    BCol,
    BSkeleton,
    BSkeletonImg,
    BTabs,
    BTab,
    BBadge,
    CardSelectGroupModal,
  },
  props: {
    singleVenue: {
      type: Boolean,
      default: false,
    },
    singleVenueFromVenueAndScreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      getVenueDataLoader: true,
      dataForVenueOrScreen: null,
      currentState: {},
      currentStateOptions: [],
      listOfVenueLoader: false,
      noVenueAvailable: false,
      isScreenSelected: false,
    }
  },
  computed: {
    isAgency() {
      return this.$store.getters['user/getSelectedGroupMemberPermissions'].includes(MemberPermissions.AGENCY)
    },
    dateSequence() {
      return this.dataForVenueOrScreen?.earningReports ? this.dataForVenueOrScreen.earningReports.last7DaysEarningsArray.map(item => item.date) : []
    },
    earningSequence() {
      return this.dataForVenueOrScreen?.earningReports ? this.dataForVenueOrScreen.earningReports.last7DaysEarningsArray.map(item => item.earning) : []
    },
    shouldShowDropDown() {
      if (this.singleVenue || this.singleVenueFromVenueAndScreen) {
        return false
      }
      return true
    },
    isUserAdmin() {
      return this.$store.getters['user/getUserRole'] === AccountTypes.ADMIN
    },
  },
  watch: {
    currentState: {
      async handler(value) {
        this.listOfVenueLoader = true
        await this.getDataforVenue(value?.id)
        this.listOfVenueLoader = false
      },
    },
  },
  async mounted() {
    if (this.singleVenueFromVenueAndScreen || this.$route.name === 'dashboard-single-venue' || this.$route.name === 'simi-network-single-venue-user' || this.$route.name === 'user-single-venue-details') {
      await this.getDataforVenue(this.$route.params.venueId)
    } else {
      await this.getListOfVenues()
      if (this.currentStateOptions.length) {
        await this.getDataforVenue(this.currentStateOptions[0].id)
      } else {
        this.noVenueAvailable = true
      }
    }
  },
  methods: {
    async getListOfVenues() {
      let res
      try {
        this.listOfVenueLoader = true
        if (this.isUserAdmin) {
          res = await this.$axios.get('admin/venue/names', {
            headers: {
              userHasGroupId: this.$route.params.userId,
            },
          })
        } else {
          res = await this.$axios.get('user/venue/names')
        }
        this.currentStateOptions = res.data.data
        if (this.currentStateOptions.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          this.currentState = this.currentStateOptions[0]
        }
      } catch (error) {
        console.error(error.message)
        showToast('Dashboard', error.message, 'danger', 4000)
      } finally {
        this.listOfVenueLoader = false
      }
    },
    async getDataforVenue(venue) {
      try {
        this.isScreenSelected = false
        this.getVenueDataLoader = true
        if (this.isUserAdmin) {
          const res = await this.$axios.get(`/admin/pmp-ad/user-dashboard-details?venueId=${venue}`)
          this.dataForVenueOrScreen = res.data.data
        } else {
          const res = await this.$axios.get(`pmp-ads/user-dashboard-details?venueId=${venue}`)
          this.dataForVenueOrScreen = res.data.data
        }
      } catch (error) {
        console.error(error.message)
        showToast('Dashboard', error.message, 'danger', 4000)
      } finally {
        this.getVenueDataLoader = false
      }
    },
    async getDataforScreen(screen) {
      let res
      try {
        this.isScreenSelected = true
        this.getVenueDataLoader = true
        if (this.isUserAdmin) {
          res = await this.$axios.get(`admin/pmp-ad/user-dashboard-details?deviceId=${screen.id}`)
        } else {
          res = await this.$axios.get(`/pmp-ads/user-dashboard-details?deviceId=${screen.id}`)
        }
        this.dataForVenueOrScreen = res.data.data
      } catch (error) {
        console.error(error.message)
        showToast('Dashboard', error.message, 'danger', 4000)
      } finally {
        this.getVenueDataLoader = false
      }
    },
    goToCreateCampaign() {
      const venue = {
        ...this.dataForVenueOrScreen.venueDetails,
        device: this.dataForVenueOrScreen.screenData,
      }
      this.$store.commit('pmpAds/SET_SELECTED_DEVICES_ON_DASHBOARD', [venue])

      if (this.isUserAdmin) {
        if (this.$route.name === 'user-single-venue-details') {
          this.$router.push({
            name: 'admin-user-external',
            params: {
              userId: this.$route.params.userId,
            },
          })
        } else {
          this.$refs['external-admin-select-group'].showLocationModal()
        }
      } else if (this.$route.name === 'dashboard-single-venue') {
        this.$router.push({
          name: 'dashboard-venue-externals',
          params: {
            venueId: this.$route.params.venueId,
          },
        })
      } else {
        this.$router.push({ name: 'simi-network-pmp-ads-edit' })
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
