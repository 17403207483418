<!-- eslint-disable vue/no-mutating-props -->
<template>
  <section>
    <b-modal
      ref="create-group-modal"
      size="md"
      no-close-on-esc
      no-close-on-backdrop
      title="Select Business"
      class="p-4"
      @ok="handleOk"
    >
      <div
        v-if="userRole === AccountTypes.ADMIN"
        md="9"
        lg="9"
        xl="9"
        class="mx-2"
      >
        <b-form-group
          text-bolder
          label="Select Business"
          class="label"
        >
          <validation-provider
            v-slot="{ errors }"
            name="Select Business"
            rules="required"
          >
            <v-select
              v-model="selectedBusiness"
              label="name"
              :options="businessOptions"
              :filterable="false"
              @open="onOpen"
              @close="onClose"
            >
              <template #list-footer>
                <li
                  v-if="hasNextPage"
                  ref="load"
                  class="loader"
                >
                  <b-skeleton
                    animation="fade"
                    width="85%"
                  />
                  <b-skeleton
                    animation="fade"
                    width="55%"
                  />
                </li>
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </div>
      <b-overlay
        :show="dataLoad"
        no-wrap
      />
    </b-modal>
  </section>
</template>

<script>
import {
  BModal, BFormGroup, BOverlay, BSkeleton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import AccountTypes from '@/common/enums/accountTypeEnum'

export default {
  components: {
    BModal,
    BOverlay,
    ValidationProvider,
    BFormGroup,
    vSelect,
    BSkeleton,
  },
  props: {
    simiNetwork: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      AccountTypes,
      selectedBusiness: null,
      AllBusinessesList: { results: [], total: 0 },
      observer: null,
      showModal: false,
      dataLoad: false,
      limit: 10,
      offset: 1,
    }
  },
  computed: {
    userRole() {
      return this.$store.getters['user/getUserRole']
    },
    businessOptions() {
      if (this.userRole === this.AccountTypes.ADMIN) {
        return this.AllBusinessesList.results.map(business => ({
          name: business.name,
          id: business.id,
        }))
      }
      return null
    },
    hasNextPage() {
      return (this.offset * this.limit) < this.AllBusinessesList.total
    },
  },
  async mounted() {
    if (this.userRole === this.AccountTypes.ADMIN) {
      await this.loadAllBusinesses()
      this.observer = new IntersectionObserver(this.infiniteScroll)
    }
  },
  methods: {
    showLocationModal() {
      this.showModal = !this.showModal
      this.$refs['create-group-modal'].show()
    },
    hide() {
      this.$refs['create-group-modal'].hide()
    },
    handleOk() {
      if (this.$route.name === 'simi-network-single-venue-user') {
        this.$router.push({
          name: 'simi-network-venue-external',
          params: {
            venueId: this.$route.params.venueId,
            userId: this.selectedBusiness.id,
          },
        })
      } else if (this.$route.name === 'simi-network-single-device-details') {
        this.$router.push({
          name: 'simi-network-venue-screen-external',
          params: {
            deviceId: this.$route.params.deviceId,
            venueId: this.$route.params.venueId,
            userId: this.selectedBusiness.id,
          },
        })
      } else if (this.$route.name === 'simi-network-single-device-user') {
        this.$router.push({
          name: 'simi-network-single-device-external',
          params: {
            deviceId: this.$route.params.deviceId,
            userId: this.selectedBusiness.id,
          },
        })
      } else if (this.$route.name === 'simi-network') {
        this.$router.push({
          name: 'simi-network-pmp-ads-edit',
          params: {
            userId: this.selectedBusiness.id,
          },
        })
      } else if (this.$route.name === 'dashboard-single-venue') {
        this.$router.push({
          name: 'dashboard-venue-details-externals',
          params: {
            venueId: this.$route.params.venueId,
            userId: this.selectedBusiness.id,
          },
        })
      } else if (this.$route.name === 'dashboard-venue-device-details') {
        this.$router.push({
          name: 'dashboard-venue-device-externals',
          params: {
            deviceId: this.$route.params.deviceId,
            venueId: this.$route.params.venueId,
            userId: this.selectedBusiness.id,
          },
        })
      } else {
        this.$router.push({
          name: 'dashboard-pmp-ads-edit',
          params: {
            userId: this.selectedBusiness.id,
          },
        })
      }
    },
    async loadAllBusinesses() {
      try {
        this.loading = true
        await this.$axios.get(`admin/user-has-group?offset=${this.offset - 1}&limit=${this.limit}&search_query=`)
          .then(({ data }) => {
            this.AllBusinessesList.results = [
              ...this.AllBusinessesList.results,
              ...data.data.results,
            ]
            this.AllBusinessesList.total = data.data.total
          })
      } catch (error) {
        console.error('Failed to load businesses:', error)
      } finally {
        this.loading = false
      }
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const { scrollTop } = ul
        this.offset += 1
        await this.loadAllBusinesses()
        await this.$nextTick()

        // Re-attach observer to the new loader element if there's a next page
        if (this.hasNextPage && this.$refs.load) {
          this.observer.disconnect()
          this.observer.observe(this.$refs.load)
        }

        ul.scrollTop = scrollTop
      }
    },
    onClose() {
      this.observer.disconnect()
    },
  },
}
</script>

<style>
.vs__dropdown-menu{
  max-height: 300px !important;
}
</style>
