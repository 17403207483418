<template>
  <div>
    <b-row>
      <b-col
        cols="12"
      >
        <h3>{{ name }}</h3>
        <validation-provider
          v-slot="{ errors }"
          rules="required"
          vid="screen-description"
        >
          <b-form-group
            class="label"
          >
            <quill-editor
              v-model="audience_profile"
              :options="editorOptions"
              style="height: 200px"
              class="mb-4"
              @change="callAudience"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BFormGroup, BRow } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { quillEditor } from 'vue-quill-editor'
import {
  required,
} from '@validations'

// Import Quill styles (choose one theme)
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {

  components: {
    BRow,
    BCol,
    ValidationProvider,
    BFormGroup,
    quillEditor,
  },
  props: {
    audienceProfile: {
      type: String,
      default: () => null,
    },
    name: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      editorOptions: {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 5, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ color: [] }, { background: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['clean'],
          ],
        },
        placeholder: `Enter your ${this.name} here...`,
        theme: 'snow',
      },
      required,
    }
  },
  methods: {
    callAudience() {
      this.$emit('audienceProfile', this.audience_profile)
    },
  },

}
</script>

<style lang="scss" scoped>
/* Add styles for Quill-generated content */
.quill-content em { font-style: italic; }
.quill-content u { text-decoration: underline; }
.quill-content s { text-decoration: line-through; }

/* Ensure <p> tags have proper spacing */
.quill-content p { margin-bottom: 1rem; }
</style>
