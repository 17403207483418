<template>
  <b-card
    no-body
    class="cardTracker"
  >
    <!-- title and dropdown -->
    <b-card-header class="pb-0">
      <h4 class="font-weight-bolder mb-25">
        Campaign Tracker
      </h4>
    </b-card-header>
    <!--/ title and dropdown -->

    <b-card-body>
      <b-row>
        <!-- chart -->
        <b-col
          sm="5"
        >
          <div class="d-flex flex-column justify-content-between">
            <span
              class="pt-4 pb-2"
            >
              <h1 class="font-large-2 font-weight-bolder mb-0 text-primary">
                {{ statsData ? statsData.totalCampaignCount : 0 }}
              </h1>
              <small>Total Campaigns</small>
            </span>
            <span class="d-flex flex-row pb-2">
              <span class="played1-tag-class featherClass d-flex align-items-center">
                <feather-icon
                  icon="CheckCircleIcon"
                  class="text-{#7367f0}"
                  size="18"
                />
              </span>
              <span class="d-flex flex-column">
                <span class="font-weight-bolder text-nowrap font-small-1 text-dark">Completed Campaigns</span>
                <span class="font-small-2 pl-1">{{ statsData ? statsData.totalCompletedPmpAds : 0 }}</span>
              </span>
            </span>
            <span class="d-flex flex-row pb-2">
              <span class="completed-tag-class featherClass d-flex align-items-center">
                <feather-icon
                  icon="ClockIcon"
                  class="text-info"
                  size="18"
                />
              </span>
              <span class="d-flex flex-column">
                <span class="font-weight-bolder text-nowrap font-small-1 text-dark">Pending Campaigns</span>
                <span class="font-small-2 pl-1">{{ statsData ? statsData.totalInProgressPmpAds : 0 }}</span>
              </span>
            </span>
            <span class="d-flex flex-row pb-2">
              <span class="orange-tag-class featherClass d-flex align-items-center">
                <feather-icon
                  icon="CheckCircleIcon"
                  class="text-{#ffa24b}"
                  size="18"
                />
              </span>
              <span class="d-flex flex-column">
                <span class="font-weight-bolder font-small-1 text-dark">Completed Ads</span>
                <span class="font-small-1 pl-1">{{ statsData ? statsData.totalCompletedAdsCount : 0 }}</span>
              </span>
            </span>
          </div>
        </b-col>
        <b-col
          sm="7"
          class="d-flex justify-content-center"
        >
          <span
            class="d-flex justify-content-start"
          >
            <b-row
              class="d-flex justify-content-between"
            >
              <!-- chart -->
              <!-- <b-col
                sm="6"
              >
                <vue-apex-charts
                  type="radialBar"
                  height="225"
                  :options="supportTrackerRadialBar.chartOptions"
                  :series="campaignCompletePercentage ? [campaignCompletePercentage] : [0]"
                />
              </b-col> -->
              <!-- <b-col
                sm="12"
              > -->
              <vue-apex-charts
                type="radialBar"
                height="325"
                :options="supportTrackerRadialBar1.chartOptions"
                :series="campaignCompletePercentage ? [campaignCompletePercentage] : [0]"
              />
              <!-- </b-col> -->
            </b-row>
          </span>
        </b-col>
        <!--/ chart -->
      </b-row>
      <!-- chart info -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
// import { $themeColors } from '../../../themeConfig'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    // BCardTitle,
    BCardBody,
    BRow,
    BCol,
  },
  props: {
    statsData: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      supportTrackerRadialBar: {
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 100,
              offsetY: 10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '0.7rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.20rem',
                },
              },
            },
          },
          colors: ['#673AB7'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: ['#673AB7'],
              inverseColors: true,
              opacityFrom: 0.5,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 10,
          },
          labels: ['Completed Campaigns'],
        },
      },
      supportTrackerRadialBar1: {
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 100,
              offsetY: 10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '0.7rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.20rem',
                },
              },
            },
          },
          colors: ['#7367f0'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: ['#7367f0'],
              inverseColors: true,
              opacityFrom: 0.5,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 10,
          },
          labels: ['Campaigns Success'],
        },
      },
    }
  },
  computed: {
    campaignCompletePercentage() {
      if (this.statsData && this.statsData.totalCompletedPmpAds) {
        return ((this.statsData.totalCompletedPmpAds / this.statsData.totalCampaignCount) * 100).toFixed()
      }
      return null
    },
    isAgency() {
      return this.$store.getters['user/getSelectedGroupMemberPermissions'].includes(MemberPermissions.AGENCY)
    },
  },
}
</script>
<style scoped>
.featherClass{
    padding: 0px 10px !important;
    margin-right: 5px !important;
}
.cardTracker{
  min-height: 380px !important;
}
.card{
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}
</style>
