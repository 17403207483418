<template>
  <section>
    <b-modal
      v-model="showModal"
      size="lg"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      title="Update Audience Profile"
      class="p-4"
    >
      <b-row>
        <b-col>
          <quill-editor
            ref="quillEditor"
            v-model="audience_profile"
            class="height-300 p-2 mb-4"
            @change="callAudience"
          />
        </b-col>
      </b-row>

      <div class="py-2 px-2 text-right">
        <b-button
          variant="primary"
          @click="submitData"
        >
          <div
            v-if="dataLoad"
            class="spinner d-inline-block mr-1"
          >
            <b-spinner small />
          </div>
          Update
        </b-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import {
  BModal,
  BButton,
  BSpinner,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { required, numeric } from '@validations'
import { min_value } from 'vee-validate/dist/rules'
import AccountTypes from '@/common/enums/accountTypeEnum'
import { showToast } from '@/common/global/functions'

// Import Quill styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    BModal,
    BButton,
    BSpinner,
    BRow,
    BCol,
    quillEditor,
  },
  props: {
    type: {
      type: String,
      required: true,
      default: '',
    },
    venueId: {
      type: Number,
      default: () => null,
    },
    deviceId: {
      type: Number,
      default: () => null,
    },
  },
  emits: ['refresh-data'],
  data() {
    return {
      showModal: false,
      dataLoad: false,
      required,
      min_value,
      numeric,
      AccountTypes,
      audience_profile: '',
    }
  },
  computed: {
    userRole() {
      return this.$store.getters['user/getUserRole']
    },
    cpm: {
      get() {
        return parseInt(this.deviceData.cpm, 10)
      },
      set(value) {
        const parsedValue = parseInt(value, 10)
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(parsedValue)) {
          this.cpmValue = parsedValue
        }
      },
    },
  },
  methods: {
    showCpmModal(data) {
      this.audience_profile = data
      this.showModal = !this.showModal
    },
    onWheel(e) {
      e.target.blur()
    },
    callAudience() {
    },
    async submitData() {
      try {
        if (this.type === 'Device') {
          this.dataLoad = true
          if (this.userRole === this.AccountTypes.ADMIN) {
            const response = await this.$axios.put(
              `admin/device/update_device_audience_profile/${this.deviceId}`,
              {
                audience_profile: this.audience_profile,
              },
            )
            showToast('Audience Profile', response.data.message.toString(), 'success')
          } else {
            const response = await this.$axios.put(
              `user/device/update_device_audience_profile/${this.$route.params.id ? this.$route.params.id : this.deviceId}`,
              {
                audience_profile: this.audience_profile,
              },
            )
            showToast('Audience Profile', response.data.message.toString(), 'success')
          }
        } else if (this.userRole === this.AccountTypes.ADMIN) {
          const response = await this.$axios.put(
            `admin/venue/update_venue_audience_profile/${this.venueId}`,
            {
              audience_profile: this.audience_profile,
            },
          )
          showToast('Audience Profile', response.data.message.toString(), 'success')
        } else {
          const response = await this.$axios.put(
            `user/venue/update_venue_audience_profile/${this.venueId}`,
            {
              audience_profile: this.audience_profile,
            },
          )
          showToast('Audience Profile', response.data.message.toString(), 'success')
        }
        this.$emit('refresh-data')
        this.showCpmModal()
      } catch (e) {
        this.$swal(e.response.data.message)
      } finally {
        this.dataLoad = false
      }
    },
  },
}
</script>

  <style scoped>
  .height-300 {
    height: 300px;
  }

  /* You can also style your Quill content if needed */
  .ql-editor {
    min-height: 200px;
  }
  </style>
