<template>
  <div class="maxh-300">
    <app-collapse
      type="margin"
      class="p-2"
    >
      <div class="h-100 overflow-y-auto">
        <app-collapse-item
          v-for="(item, index) in screens"
          :key="index"
          :title="item.name"
        >

          <div
            class="d-flex justify-content-between"
          >
            <span>
              <strong>
                Start Time:
              </strong>
            </span>
            <span>
              {{ item.op_start_time || '' }}
            </span>
          </div>
          <div
            class="d-flex justify-content-between"
          >
            <span>
              <strong>
                End Time:
              </strong>
            </span>
            <span>
              {{ item.op_end_time || '' }}
            </span>
          </div>
          <div
            class="d-flex justify-content-between pt-1"
          >
            <span>
              <strong>
                Height:
              </strong>
            </span>
            <span>
              {{ item.height || 0 }} px
            </span>
          </div>
          <div
            class="d-flex justify-content-between"
          >
            <span>
              <strong>
                Width:
              </strong>
            </span>
            <span>
              {{ item.width || 0 }} px
            </span>
          </div>
          <div
            class="d-flex justify-content-between pt-1"
          >
            <span>
              <strong>
                Last Updated:
              </strong>
            </span>
            <span>
              {{ lastUpdatedDate(item.updated_at) || '--' }}
            </span>
          </div>
        </app-collapse-item>
      </div>
    </app-collapse>
  </div>
</template>

<script>
import moment from 'moment'
import AppCollapse from '@/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
  },
  props: {
    screens: {
      type: Array,
      default: () => null,
    },
  },
  methods: {
    lastUpdatedDate(date) {
      return moment(date).utc().format('ddd, MMM Do YYYY, HH:mm:ss')
    },
  },
}

</script>
<style scoped>
/* Add these styles */
.h-100 {
  height: 100%;
}

.overflow-y-auto {
  overflow-y: auto;
}

.flex-grow-1 {
  flex-grow: 1;
}

.overflow-hidden {
  overflow: hidden;
}
.maxh-300 {
  max-height: 275px !important;
  min-width: 400px;
  overflow-y: auto !important;
}

.maxh-300::-webkit-scrollbar {
  width: 6px;
}
.maxh-300::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 50px;
}

.maxh-300::-webkit-scrollbar-thumb {
  background-color: #4c92fc;
  border-radius: 50px;
}

</style>
